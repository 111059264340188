import React from 'react';

function Hero() {
  return (
    <section className="hero" id="home">
      <div className="hero-banner">
        <h1>continue exploring</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae explicabo debitis est autem dicta.</p>
        <a href="#tours" className="btn hero-btn">
          explore tours
        </a>
      </div>
    </section>
  );
}

export default Hero;
